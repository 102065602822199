<template>
	<form class="form form--user form-user" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="loading || isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row">
				<text-field class="form__field" label="Email Address" type="email" v-model="fields.email" :required="true">
					<button class="button" type="button" :disabled="!fields.email" slot="post" @click="salesforceQuery({ email: fields.email })">Search Salesforce</button>
				</text-field>
			</div>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" v-model="fields.firstName" :required="true" />
				<text-field class="form__field" label="Last Name" v-model="fields.lastName" :required="true" />
				<text-field class="form__field" label="Phone" v-model="fields.phone" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="userStatus" />
				<autocomplete-organization @selected="orgSelect" :value="fields.orgId" :label="(fields.organization || {}).name" />
			</div>

			<h2 class="form-section__title">Address Information</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
				<text-field class="form__field" label="Postal Code" v-model="fields.address.postal" />
			</div>

			<h2 class="form-section__title">Contact</h2>
			<div class="form-row">
				<template v-if="canSendWelcome">
					<radio-field name="welcomeEmail" id="sendMailDefault" label="Send Resource Center Welcome Email" value="default" v-model="fields.sendMail" />
					<radio-field name="welcomeEmail" id="sendMailArc" label="Send ARC Welcome Email" value="arc" v-model="fields.sendMail" :disabled="!fields.permissions['arc']" v-if="appIds.includes('arc')" />
					<radio-field name="welcomeEmail" id="sendMailBond" label="Send Bond Insight Welcome Email" value="bond" v-model="fields.sendMail" :disabled="!fields.permissions['bond-insight']" v-if="appIds.includes('bond-insight')" />
				</template>
				<template v-else>
					<checkbox class="form__field" label="Send Password Reset" v-model="fields.sendMail" />
				</template>
			</div>

			<h2 class="form-section__title">Permissions</h2>
			<div class="form-row">
				<checkbox class="form__field" v-for="(app, key) in applications" :key="`application_${key}`" name="permission" :label="app.name" v-model="fields.permissions[app.appId]" />
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Job Title" v-model="fields.settings.jobTitle" />
				<select-field class="form__field" label="Marketing Title" v-model="fields.settings.marketingTitle" :options="marketingTitleOptions" />
				<text-field class="form__field" label="Salesforce ID" v-model="fields.settings.salesforceId" />
			</div>

			<template v-if="fields.permissions.arc">
				<h2 class="form-section__title">ARC Daily Emails</h2>
				<div class="form-row form-row--col-5">
					<template v-for="(day, key) in arcDays">
						<checkbox class="form__field" v-model="fields.settings.arcDaily[key]" :value="key" :key="key" :label="day" />
					</template>
				</div>
			</template>

			<div class="alert alert--fail" v-if="error" v-html="error"></div>
			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading || isLoading">
					{{ fields.uid ? 'Update' : 'Create' }} User
				</a-button>

				<button class="button button--critical form__action" type="button" :disabled="loading || isLoading" @click="remove(fields)" v-if="fields.uid && fields.status != 'removed'">
					Remove User
				</button>
			</div>

		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import RadioField from '@/components/RadioField'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { UserStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { pick, get } from 'lodash'

export default {
	name: 'FormUser',
	components: {
		AButton,
		AutocompleteOrganization,
		Checkbox,
		LoadingSpinner,
		RadioField,
		SelectField,
		TextField,
	},
	props: {
		loading: Boolean,
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			address: {},
			permissions: {},
			settings: {
				arcDaily: [],
			},
		},
		error: null,
		fields: {},
		isLoading: false,
		states: stateList,
		arcDays: {
			1: 'Monday',
			2: 'Tuesday',
			3: 'Wednesday',
			4: 'Thursday',
			5: 'Friday',
		},
		marketingTitles: [
			'CEO',
			'CFO',
			'CLO',
			'CCO',
			'CAO',
			'CBO',
			'CRO',
			'Lender',
			'Credit Officer',
			'Administrator',
			'Sales Officer',
			'Director',
			'Managing Director',
			'VP',
			'AVP',
		],
	}),
	computed: {
		organizations() {
			const orgs = this.$store.getters['organization/organizations'].filter(o => o.status != 'removed')

			const organizations = []
			for (const org of orgs) {
				organizations.push({
					value: org.orgId,
					label: [org.name, org.address.city, org.address.state].join(' - '),
				})
			}
			return organizations
		},
		me() {
			return this.$store.getters['user/me']
		},
		isCoreUser() {
			return this.user?.email?.match(/(@(5inline|southstatebank|southstateduncan)\.com)$/i)
		},
		applications() {
			let apps = [...this.$store.getters['application/applications']]

			apps = apps.filter(app => {
				return app.appId != 'bval' && app.appId != 'recon'
			})
			apps.sort((a, b) => (a.name > b.name) ? 1 : -1)

			if (this.me?.permissions?.super) {
				if (this.me?.permissions?.arc && this.isCoreUser) {
					apps.unshift(
						{
							appId: 'bval_admin',
							name: 'BVAL | Admin',
						},
						{
							appId: 'bval',
							name: 'BVAL | Full Access',
						},
						{
							appId: 'bval_ssb',
							name: 'BVAL | SouthState Only',
						},
						{
							appId: 'recon',
							name: 'RECON',
						},
					)
					apps.sort((a, b) => (a.name > b.name) ? 1 : -1)
					apps.unshift(
						{
							appId: 'super',
							name: 'Super Admin',
						},
						{
							appId: 'admin',
							name: 'Administrator',
						}
					)
				}
			}
			if (this.me?.permissions?.bval_admin) {
				if (this.isCoreUser) {
					apps = [
						{
							appId: 'bval',
							name: 'BVAL | Full Access',
						},
						{
							appId: 'bval_ssb',
							name: 'BVAL | SouthState Only',
						},
					]
				} else {
					apps = []
				}
			}
			return apps
		},
		appIds () {
			return this.applications.map((app) => app.appId)
		},
		userStatus() {
			let status = []
			for (let key in UserStatus) {
				status.push({
					value: key,
					label: UserStatus[key],
				})
			}

			return status
		},
		marketingTitleOptions() {
			const options = []
			for (const k of this.marketingTitles) {
				options.push({ value: k, label: k, })
			}
			return options
		},
		canSendWelcome() {
			return !this.fields.uid || !this.user.dateLogin
		},
	},
	methods: {
		orgSelect(selected) {
			this.fields.orgId = selected.value
			this.fields.orgSearch = selected.label
			this.fields.organization = selected
		},
		setArcSettings(key, state) {
			this.fields.settings.arcDaily = this.fields.settings.arcDaily || {}
			this.fields.settings.arcDaily[key] = state
		},
		setWelcome(val) {
			this.fields.sendMailType = val
			this.$set(this.fields, 'sendMailType', val)
		},
		submitForm(fields) {
			this.error = null
			if (fields.uid) {
				this.isLoading = true
				this.$store.dispatch('user/update', { uid: fields.uid, data: fields }).then((res) => {
					this.fields.sendMail = false
					let messages = [`User updated.`, ...(res.messages || [])]
					this.$notice(messages.join(`<br />`))
				}).catch(error => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
			} else {
				this.isLoading = true
				this.$store.dispatch('user/create', fields).then((res) => {
					this.fields.sendMail = false
					let messages = [`User created.`, ...(res.messages || [])]
					this.$notice(messages.join(`<br />`))
				}).catch(error => {
					this.error = error.message || error
				})
				.finally(() => {
					this.isLoading = false
				})
			}
		},
		remove(fields) {
			if (!fields.uid) return

			this.$confirm(`Are you sure you want to remove "${fields.firstName} ${fields.lastName}"?`, () => {
				this.error = null
				this.isLoading = true
				this.$store.dispatch('user/remove', fields.uid).then(() => {
					this.$notice(`User removed!`)
					this.$router.back()
				}).catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.isLoading = false
				})

			})
		},
		async salesforceQuery(params) {
			this.isLoading = true
			try {
				const res = await this.$store.dispatch('user/salesforceQuery', params)
				if (!res.length) {
					this.$confirm(`No user found in Salesforce with email: "${params.email.toLowerCase()}"`, () => {

					}, {
						title: `Not Found`,
						showCancel: false,
						acceptLabel: 'Close',
					})
				} else {
					const [_found] = res
					this.$confirm(`Name: ${_found.firstName} ${_found.lastName} <br />Email: ${_found.email}<br />Organization: ${_found.organization?.name}<br />Phone: ${_found.phone}<br />Salesforce ID: ${_found.salesforceId}<p>Copy user information?</p>`, () => {
						const _keys = ['firstName', 'lastName', 'email', 'phone', 'address', 'settings', 'status', 'permissions', 'organization', ]
						for (const _key of _keys) {
							let val = _found[_key]
							if (val) {
								if (_key == 'status') {
									val = val.toLowerCase()
								}
								this.fields[_key] = val
							}
						}
					}, {
						title: `User Found in Salesforce`,
						acceptLabel: 'Copy User',
					})
				}
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			} finally {
				this.isLoading = false
			}
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function( userProp) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(userProp, ['uid', 'address', 'email', 'firstName', 'lastName', 'organization', 'permissions', 'phone', 'status',]),
					...{ orgId: get(userProp, 'organization.orgId') },
					...{ settings: {...this.defaults.settings, ...userProp.settings }}
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
</style>
