<template>
	<div class="mdc-form-field">
		<div class="mdc-radio mdc-radio--touch" :class="{'mdc-radio--disabled':attrs.disabled}">
			<input class="mdc-radio__native-control" type="radio" :checked="isChecked" :id="id" :name="name" :value="value" v-bind="attrs" v-on="listeners">
			<div class="mdc-radio__background">
				<div class="mdc-radio__outer-circle"></div>
				<div class="mdc-radio__inner-circle"></div>
			</div>
			<div class="mdc-radio__ripple"></div>
		</div>
		<label :for="id" v-if="label" v-html="label"></label>
	</div>
</template>

<script>
import { MDCFormField } from '@material/form-field'
import { MDCRadio } from '@material/radio'
import { v4 } from 'uuid'

export default {
	name: 'radioField',
	model: {
		prop: 'modelValue',
		event: 'change',
	},
	data: () => ({
		radio: null,
		formField: null,
	}),
	props: {
		id: {
			type: String,
			default: v4(),
		},
		name: String,
		label: String,
		value: {
			type: String|Boolean,
			default: '',
		},
		modelValue: String|Boolean,
	},
	computed: {
		attrs() {
			return this.$attrs
		},
		listeners() {
			return {
				...this.$listeners,
				change: event => this.change(event),
				click: event => this.click(event),
			}
		},
		isChecked() {
			return this.modelValue == this.value
		},
		model: {
			get() {
				return this.value
			},
			set(value) {
				console.log('set', value)
			}
		}
	},
	mounted() {
		this.radio = new MDCRadio(this.$el.querySelector('.mdc-radio'))
		this.formField = new MDCFormField(this.$el)
		this.formField.input = this.radio
	},
	methods: {
		change(event) {
			if (event?.target) {
				this.$emit('change', event.target.value)
			}
		},
		click(event) {
			if (event?.target?.value == this.modelValue) {
				this.$emit('change', '')
			}
		}
	},
}
</script>

<style scoped lang="scss">
@use "@material/radio/mdc-radio";
@use "@material/form-field";

.mdc-form-field {
	display: flex;
	flex-direction: row;
	align-items: center;

	.mdc-radio {
		margin-left: -12px;

		&__native-control:enabled:checked + .mdc-radio__background {
			.mdc-radio__outer-circle,
			.mdc-radio__inner-circle {
				border-color: modules.color_('primary');
			}
		}

		.mdc-radio__ripple {
			&::before,
			&::after {
				background-color: modules.color_('primary');
			}
		}
	}

	label {
		cursor: pointer;
	}
}
</style>
