<template>
	<div class="partial partial--users users">
		<div class="partial__heading">
			<ion-icon name="people-circle-outline" size="large" class="partial__icon"></ion-icon>
			<h1 class="partial__title">Manage Users</h1>

			<div class="partial__actions">
				<router-link :to="{name: 'user' }" class="button">
					<div class="mdc-button__ripple"></div>
					<ion-icon name="add-circle-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
					<span class="mdc-button__label">Add User</span>
				</router-link>
			</div>
		</div>

		<form class="form partial__form" :class="{'partial__form--visible':formVisible}" @submit.prevent="submitForm(fields)" autocomplete="off">
			<button class="button partial__form-search" @click="formVisible=!formVisible">
				<ion-icon name="search-outline" class="mdc-button__icon" aria-hidden="true"></ion-icon>
				<span class="mdc-button__label">Search</span>
			</button>
			<div class="form-section">
				<div class="form-row form-row--col-3">
					<text-field class="form__field" label="Name" v-model="fields.name" type="text" autocomplete="nope">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="Email" v-model="fields.email" type="text" autocomplete="nope">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="Organization" v-model="fields.organization" type="text" autocomplete="nope">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<text-field class="form__field" label="Salesforce ID" v-model="fields.salesforceId" type="text" autocomplete="nope">
						<span slot="pre"><ion-icon name="search-outline"></ion-icon></span>
					</text-field>
					<!-- <select-field class="form__field" name="orgId" label="Organization" v-model="fields.orgId" :options="organizations" /> -->
					<!-- <autocomplete-organization class="form__field" label="Organization" @select="orgSelect" autocomplete="off" /> -->
					<!-- <autocomplete-field class="form__field" label="Organization" @input="orgSearch" v-model="fields.orgSearch" :options="orgOptions" @select="orgSelect" autocomplete="off" /> -->
					<!-- <input type="hidden" v-model="fields.orgId" /> -->
					<select-field class="form__field" label="Status" v-model="fields.status" :options="userStatus" />
					<div>
						<button type="submit" class="button button--unelevated form__button">Search</button>
						<button type="cancel" class="button form__button button--primary" @click="reset()">clear</button>
					</div>
				</div>
			</div>
		</form>

		<div class="partial__body users__body">
			<list-users :users="users" :loading="loading" />
			<pagination :limit="limit" :count="usersCount" />
			<button type="button" class="button form__button button--secondary button--right" @click="report()">export</button>
		</div>

		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import AutocompleteField from '@/components/AutocompleteField'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import ListUsers from '@/components/ListUsers'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { UserStatus, OrganizationStatus } from '@/lib/enums'
import { forceDownload } from '@/lib/utils'

export default {
	name: 'PartialUsers',
	components: {
		AutocompleteField,
		AutocompleteOrganization,
		ListUsers,
		Modal,
		Pagination,
		SelectField,
		TextField,
	},
	data: () => ({
		fields: {
			name: '',
			email: '',
			organization: '',
			orgId: '',
			status: '',
		},
		formVisible: false,
		fromQuery: null,
		limit: 25,
		orgOptions: [],
		orgLoading: false,
	}),
	computed: {
		loading() {
			return this.$store.getters['misc/loading']
		},
		modalVisible() {
			return this.$route.name == 'user'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		organizations() {
			const orgs = this.$store.getters['organization/organizations']
			const _list = orgs.filter(o => o.status != 'removed').map(o => { return { value: o.orgId, label: o.nameAddress.join(' - ')} } ).sort((a, b) => a.label > b.label ? 1: -1)
			return [...[{ value: undefined, label: ''}], ..._list]
		},
		users() {
			return this.$store.getters['user/users'].map(user => ({ ...user, nameEmail: [user.profileName, user.email].join(`<br />`)}) )
		},
		usersCount() {
			return this.users.length
		},
		userStatus() {
			const stats = Object.keys(UserStatus).map(k => { return { value: k, label: UserStatus[k] }})
			return [...[{ value: undefined, label: ''}], ...stats]
		},
		p() {
			return Number(this.$route.query.p || 0)
		},
		// orgOptions() {
		// 	return ['Item', 'Item', 'Item']
		// },
	},
	metaInfo: () => ({
		title: 'Users',
	}),
	async mounted() {
		await this.$store.dispatch('application/list')
		await this.$store.dispatch('organization/list')
	},
	methods: {
		hideModal(clicked) {
			this.$router.replace({ name: 'users', query: this.fromQuery })
		},
		orgSelect(selected) {
			this.fields.orgId = selected.value
			this.fields.orgSearch = selected.label
		},
		async submitForm(fields) {
			const _keys = []
			const _vals = []
			for (const key in fields) {
				if (fields[key]) {
					_keys.push(`keywords.${key}`)
					_vals.push(fields[key])
				}
			}

			let keys = _keys.join(`,`)
			let vals = _vals.join(`,`)

			try {
				if (keys && vals) {
					this.$router.push({ query: { key: keys, op: 'array-contains', value: vals }})
				} else {
					this.$router.push({ query: {}})
				}
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}
		},
		async report() {
			const _keys = []
			const _vals = []
			for (const key in this.fields) {
				if (this.fields[key]) {
					_keys.push(`keywords.${key}`)
					_vals.push(this.fields[key])
				}
			}

			let keys = _keys.join(`,`)
			let vals = _vals.join(`,`)
			const exported = await this.$store.dispatch('user/report', {key: keys, value: vals, op: keys && vals ? 'array-contains' : ''})

			try {
				forceDownload(exported, `Users Export.csv`)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}

		},
		reset() {
			this.fields = {
				orgId: '',
			}
			this.submitForm(this.fields)
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function (to, from) {
				if (from && from.name == 'users' && to.name == 'user') return this.fromQuery = from.query
				if (from && from.name == 'user'
					&& JSON.stringify(to.query) == JSON.stringify(this.fromQuery)
				) return
				// if (from && from.name == 'user') return
				this.$store.dispatch('user/list', {...to.query, p: to.query.p || 1, limit: this.limit, orderBy: to.query.orderBy || 'dateLogin', order: to.query.order || 'asc'})
					.catch(error => {
						this.$notice(`ERROR: ${error.message || error}`)
					})

				if (to.query?.key) {
					let _keys = to.query.key?.split(',') || []
					let _vals = to.query.value?.split(',') || []
					_keys.forEach((key, i) => this.fields[key.replace(/keywords\./i,'')] = _vals[i])
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">
.pagination {
	@include modules.gutter('padding');
	align-items: center;
	color: modules.color_('text');
	display: flex;
	justify-content: center;

	ion-icon {
		color: modules.color_('text', 'light');
		cursor: pointer;
		@include modules.fontSize(24px);

		&:hover {
			color: modules.color_('secondary');
		}
	}

	&__page {
		@include modules.fontSize(15px);
		font-weight: 500;
	}
}

.button {
	&--right {
		display: block;
		margin-left: auto;
	}
}
</style>
