<template>
	<div class="modal modal--user modal-user">
		<div class="modal__loading" v-if="loading">
			<loading-spinner />
		</div>
		<div class="modal-user__form">
			<form-user :user="user" />
		</div>
		<div class="modal-user__stats">
			<dl>
				<dt>Last Login</dt>
				<dd>
					{{ user.dateLogin | date('PPp')}}<br />
					{{ user.dateLogin | timeAgo }}<br />
					<a @click="resetLoginFail">Reset Login Counter</a><br />
					<a @click="resetMfa" v-if="user.otpDate">Reset MFA</a>
				</dd>
				<dt>Date Created</dt>
				<dd>{{ user.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ user.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ user.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ user.modifiedByName }}</dd>
			</dl>
		</div>
	</div>
</template>

<script>
import FormUser from '@/components/FormUser'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'ModalUser',
	components: {
		FormUser,
		LoadingSpinner,
	},
	data: () => ({
		loading: false,
	}),
	async mounted() {
		this.loading = true
		await this.$store.dispatch('application/list')
		if (this.$route.params.uid) {
			await this.$store.dispatch('user/get', this.$route.params.uid)
		}
		this.loading = false
	},
	destroyed() {
		this.$store.dispatch('user/unset')
	},
	computed: {
		user() {
			return this.$store.getters['user/user']
		},
	},
	methods: {
		resetLoginFail() {
			this.loading = true
			this.$store.dispatch(`user/resetFail`, this.$route.params.uid)
				.then((res) => {
					this.$notice(`Login counter reset`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.loading = false
				})
		},
		resetMfa() {
			this.loading = true
			this.$store.dispatch(`user/resetMfa`, this.$route.params.uid)
			.then((res) => {
					this.$notice(`MFA reset for user`)
				})
				.catch((error) => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
				.finally(() => {
					this.loading = false
				})
		}
	},
}
</script>

<style scoped lang="scss">
.modal-user {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
